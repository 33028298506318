import React, { useState, useEffect, useMemo } from 'react';
import Image from "next/image";
import style from "./index.module.scss";
import styles from "@home3/pc/Influencers/index.module.scss";
import buriedSdk from "@/utils/zz-buried";
import { useTranslation } from "next-i18next";
import { useWidth } from "@/utils/resize";

type Props = {
  list: Array<{
    id: number,
    title: string,
    icon: any,
    img: any,
    desc: string
  }>,
  title: string,
  reverse?: boolean
}

const ImageList = (props: Props) => {
  const { t } = useTranslation(['home'])
  const { width } = useWidth();

  const [hoveredImageId, setHoveredImageId] = useState<any>();

  const images = props.list;

  useEffect(() => {
    setHoveredImageId(props.list[0]?.id ?? null)
  }, [])

  const hoveredImage = useMemo(() => {
    return props.list.find(item => item.id === hoveredImageId)
  }, [hoveredImageId])


  const handleMouseEnter = (imageId) => {
    setHoveredImageId(imageId);
  };

  return (
    <div className='animate-box mb-[50px] lg1024:mb-[0px] flex flex-col items-center'>
      <h2 className={style.sectionTitle}>{ props.title }</h2>
      <div className={`${props.reverse ? 'flex-row-reverse' : ''} ${style.sectionWrap} flex justify-center gap-[100px] items-center h-[650px] lg1024:hidden`}>
        <ul className="gap-[34px] w-[50%]">
          {images.map((image) => (
            <li
              key={image.id}
              className='hover:bg-[#FFF7F8] hover:text-[#FF3E5F] p-[10px] flex gap-[16px] ant cursor-pointer mb-[29px]'
              onMouseEnter={() => handleMouseEnter(image.id)}
            >
              <div className="w-[55px] mr-[14px]">
                <Image src={image.icon} alt='icon' />
              </div>
              <div className="flex-1">
                <h3 className={`${style.title} font-[800] text-[30px] mb-[14px] leading-[30px]`}>{image.title}</h3>
                <p className="text-[14px] !text-[#141414]">{image.desc}</p>
              </div>
            </li>
          ))}
          <li>
            <a
              href={`${process.env.portalUrl}/${props.reverse ? 'products/sales?name=hot' : 'discovery/videos' }`}
              className={style.get}
              target={'_blank'}
              onClick={() => {
                buriedSdk.buried({
                  event: `Shoplus官网_${width > 1024 ? "Web" : "H5"}`,
                  scene: `${!props.reverse ? 'For Creator' : 'For Seller'}_按钮点击_Get Started`,
                  URL: process.env.publicApiDomain,
                });
              }} rel="noreferrer"
            >
              {t('Get Started')}
            </a>
            <a
              href={`${process.env.portalUrl}/checkout`}
              className={style.plan}
              target={'_blank'}
              onClick={() => {
                buriedSdk.buried({
                  event: `Shoplus官网_${width > 1024 ? "Web" : "H5"}`,
                  scene: `${!props.reverse ? 'For Creator' : 'For Seller'}_按钮点击_View Plans`,
                  URL: process.env.publicApiDomain,
                });
              }} rel="noreferrer"
            >
              {t('View Plans')}
            </a>
          </li>
        </ul>
        <div className='ant w-[50%]'>
          {hoveredImage ? (
            <Image src={hoveredImage.img} alt={hoveredImage.title} />
          ) : null}
        </div>
      </div>

      <ul className={"hidden lg1024:block mb-[0px]"}>
        {images.map((image) => (
          <li
            key={image.id}
            className={`${style.sectionWrapMobile} ant cursor-pointer`}
            onMouseEnter={() => handleMouseEnter(image.id)}
          >
            <p className={style.title}>{image.title}</p>
            <p className={style.desc}>{image.desc}</p>
            <Image src={image.img} alt={image.title} />
          </li>
        ))}
        <li className='px-[10px]'>
          <a
            href={`${process.env.mobileUrl}/rank?type=${props.reverse ? 'Goods' : 'Videos'}&rankType=breakout`}
            className={style.get}
            target={'_blank'}
            onClick={() => {
              buriedSdk.buried({
                event: `Shoplus官网_${width > 1024 ? "Web" : "H5"}`,
                scene: `${!props.reverse ? 'For Creator' : 'For Seller'}_按钮点击_Get Started`,
                URL: process.env.publicApiDomain,
              });
            }} rel="noreferrer"
          >
            {t('Get Started')}
          </a>
          <a
            href={`${process.env.mobileUrl}/checkout`}
            className={style.plan}
            target={'_blank'}
            onClick={() => {
              buriedSdk.buried({
                event: `Shoplus官网_${width > 1024 ? "Web" : "H5"}`,
                scene: `${!props.reverse ? 'For Creator' : 'For Seller'}_按钮点击_View Plans`,
                URL: process.env.publicApiDomain,
              });
            }} rel="noreferrer"
          >
            {t('View Plans')}
          </a>
        </li>
      </ul>
    </div>
  );
};

export default ImageList;
